import React from "react"
import uxImage from "../assets/user-ui.jpg"
import codingImage from "../assets/coding.jpg"
import unity from "../assets/casestudies/unity-data.png"
import simpsonsThum from "../assets/simpsons.jpg"
import CenteredContent from "../components/centeredContent"
import FullWidthContent from "../components/fullWidthContent"
import Layout from "../components/layout"

const IndexPage = ({ data }) => (
  <Layout
    headerType="home"
    headerText={
      "Hi, I'm Gus Higuera a Software Engineering Manager in Austin, TX."
    }
    subText={"In my spare time I also write comic books too =)"}
  >
    <CenteredContent
      image1={codingImage}
      header="Engineering Leadership"
      body="For over 20 years, I've been working in software engineering and leveraging various technologies on both the front end and server side to deliver exceptional products and memorable experiences for a wide range of companies, including PayPal, Dun & Bradstreet, Warner Bros., EA Games, FOX, Idealab, and car2go. As an Engineering Manager, I have a proven track record of guiding teams to success, fostering innovation, and driving strategic initiatives."
      rotation="3deg"
      buttonText="See my portfolio"
      buttonLink="/portfolio"
    />
    <FullWidthContent
      image={uxImage}
      header="Technology & Design"
      body="In addition to my engineering work, I've had the opportunity to collaborate with exceptional design teams as a user experience designer and creative director. This experience enhanced my skills as an engineering leader with a strong focus on user-centric solutions."
      rotation="3deg"
      topTextMargin="-25px"
      bottomAngleMargin="-40px"
      buttonText="See some of my design work"
      buttonLink="/portfolio/dnb"
    />
    <CenteredContent
      image1={unity}
      header="Data Pipeline Management"
      body="In my current role, I oversee the Subscription and Metered Billing teams for the Commerce Platform team at Unity, managing intricate data pipelines that are responsible for generating millions of dollars in revenue and tracking billions of transactions."
      rotation="-3deg"
      buttonText="See my latest work"
      buttonLink="/portfolio/unity"
    />
    <FullWidthContent
      image={simpsonsThum}
      header="Award Winning Work"
      body="Throughout my career, I've had the privilege of collaborating with exceptional teams and earning recognition, including the Webby Award-winning site for The Simpsons Movie and the Ady for the Green Car Hawaii application"
      bottomAngleMargin="-40px"
      bgcolor1={"#ffcc00"}
      topTextMargin="-35px"
      rotation="-3deg"
      buttonText="See my LinkedIN profile"
      buttonLink="https://www.linkedin.com/in/gushiguera"
    />
  </Layout>
)

export default IndexPage
